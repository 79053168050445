@font-face {
  font-family: 'Manrope';
  src: local('Manrope'), url(./assets/fonts/Manrope/Manrope-VariableFont_wght.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* For Chrome */
/* width */
::-webkit-scrollbar {
  @media all and (min-width: 990px) {
    width: 4px;
    height: 2px;
  }
}

@media all and (min-width: 990px) {
  ::-webkit-scrollbar {
    width: 15px;
    height: 10px;
  }
}


/* Track */
::-webkit-scrollbar-track {
  background: #c4c4c4;
  border-radius: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #794cec;
  height: 24px;
  width: 24px;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #7146df;
}